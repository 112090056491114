import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const FinanceSolutions: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabPrefix = `cases.finance.solutions.tab_${activeTab}`;

  const cases = [
    {
      title: 'cases.finance.solutions.tab_0.title',
      screen: '/images/directions/finance/cases/upstox.png',
      url: '/cases/projects/upstox/',
    },
    {
      title: 'cases.finance.solutions.tab_1.title',
      screen: '/images/directions/finance/cases/sbi.png',
      url: '/cases/projects/sbi-bank/',
      note: true,
    },
    {
      title: 'cases.finance.solutions.tab_2.title',
      screen: '/images/directions/finance/cases/vtb.png',
      url: '/cases/projects/vtb/',
    },
    {
      title: 'cases.finance.solutions.tab_3.title',
      screen: '/images/directions/finance/cases/mfo.png',
      url: '/cases/projects/registry-mfo/',
      note: true,
    },
    {
      title: 'cases.finance.solutions.tab_4.title',
      screen: '/images/directions/finance/cases/kassir.png',
      url: '',
    },
  ];

  const featuresOnTab = [
    [...Array(6)],
    [...Array(5)],
    [...Array(5)],
    [...Array(4)],
    [...Array(4)],
  ];

  const cards = [
    '/images/directions/finance/icons/investing.svg',
    '/images/directions/finance/icons/bank.svg',
  ];

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>
        <h2>{t('cases.finance.solutions.title')}</h2>
        <p>{t('cases.finance.solutions.desc')}</p>
        <div className={styles.cardsContainer}>
          {cards.map((item, index) => (
            <div key={`card-${index + 1}`} className={styles.card}>
              <span className={styles.borderLine} />
              <div className={styles.icon}>
                <img src={item} alt="" />
              </div>
              <div className={styles.text}>
                {t(`cases.finance.solutions.cards.${index}.title`)}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttonsContainer}>
          {cases.map((item, index) => (
            <button
              key={item.title}
              type="button"
              className={`${styles.button} ${
                activeTab === index ? styles.button__active : ''
              }`}
              onClick={() => setActiveTab(index)}
            >
              {t(item.title)}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.tabContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.desc}>{t(`${tabPrefix}.desc`)}</div>
          <div className={styles.featuresContainer}>
            {featuresOnTab[activeTab].map((item, index) => (
              <div key={`feature-${index + 1}`} className={styles.feature}>
                <div className={styles.feature__text}>
                  {t(`${tabPrefix}.features.${index}.title`)}
                </div>
              </div>
            ))}
          </div>
          {cases[activeTab].note ? (
            <p className={styles.note}>{t(`${tabPrefix}.note`)}</p>
          ) : (
            ''
          )}
          {cases[activeTab].url ? (
            <div className={styles.desktopLink}>
              <a href={cases[activeTab].url}>
                {t('cases.finance.solutions.linkName')}
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="#D761AD"
                  />
                </svg>
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={styles.rightContainer}>
          <img src={cases[activeTab].screen} alt="" />
          {cases[activeTab].url ? (
            <div className={styles.mobileLink}>
              <a href={cases[activeTab].url}>
                {t('cases.finance.solutions.linkName')}
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="#D761AD"
                  />
                </svg>
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default FinanceSolutions;
