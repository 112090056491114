/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const FinanceGuarantees: FC = () => {
  const prefix = 'cases.finance.guarantees';

  const cards = [
    '/images/directions/finance/icons/warranty_repair.svg',
    '/images/directions/finance/icons/warranty_verified.svg',
  ];

  return (
    <div className={styles.sectionWrap}>
      <div className={styles.wrap}>
        <h2>{t(`${prefix}.title`)}</h2>
        <div className={styles.cards}>
          {cards.map((item, index) => (
            <div key={`card-${index + 1}`} className={styles.card}>
              <img src={item} alt="" />
              {t(`${prefix}.cards.${index}`)}
            </div>
          ))}
        </div>
        <p className={styles.desc}>{t(`${prefix}.desc`)}</p>
      </div>
    </div>
  );
};

export default FinanceGuarantees;
