/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { FinanceButton } from '../../components/FinanceButton';

const FinanceMvp: FC = () => {
  const prefix = 'cases.finance.mvp';

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.sectionWrap}>
      <div className={styles.wrap}>
        <h2>{t(`${prefix}.title`)}</h2>
        <p className={styles.desc}>{t(`${prefix}.desc`)}</p>
        <div className={styles.offer}>
          <div className={styles.icon}>
            <img src="/images/directions/finance/icons/fire.svg" alt="" />
          </div>
          <div className={styles.content}>
            <h3>{t(`${prefix}.offer.title`)}</h3>
            <p>{t(`${prefix}.offer.desc`)}</p>
            <div className={styles.lists}>
              <div className={styles.list}>
                {t(`${prefix}.offer.price.title`)}
                <span className={styles.value}>
                  {t(`${prefix}.offer.price.content`)}
                </span>
              </div>
              <div className={styles.list}>
                {t(`${prefix}.offer.time.title`)}
                <span className={styles.value}>
                  {t(`${prefix}.offer.time.content`)}
                </span>
              </div>
            </div>
          </div>
          <button onClick={scrollToForm} className={styles.link}>
            <img src="/images/directions/finance/icons/btn_arrow.svg" alt="" />
          </button>
          <div className={styles.btnMobileContainer}>
            <FinanceButton onClick={scrollToForm}>
              {t(`cases.finance.header.button`)}
            </FinanceButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceMvp;
