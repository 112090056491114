/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import { IReviewsQueryEdge } from '../../../queries/reviews/types';
import { usePathPrefix } from '../../../constants/hooks';
import { t } from '../../../i18n';

// eslint-disable-next-line @typescript-eslint/ban-types
type IProps = IReviewsQueryEdge['node']['frontmatter']['ratings'] & {};

const roundToHalf = (num: number) => Math.round(num / 0.5) * 0.5;

const ReviewCardRating: FC<IProps> = ({ quality, cost, refer, schedule }) => {
  const prefix = usePathPrefix();

  const rating = useMemo(() => {
    const mid = (quality + cost + refer + schedule) / 4;
    return Math.min(5, Math.max(0, roundToHalf(mid)));
  }, [quality, cost, refer, schedule]);

  const withHalf = useMemo(() => rating % 1 > 0.4, [rating]);

  const full = Math.floor(rating);
  const empty = 5 - Math.ceil(rating);

  return (
    <div className={styles.wrap}>
      <div className={styles.rating}>
        <div className={styles.rating__title}>{rating}</div>
        <div className={styles.rating__stars}>
          {[...new Array(Math.floor(full))].map((_, i) => (
            <img src={`${prefix}/reviews/star.svg`} key={i} alt="" />
          ))}

          {withHalf && <img src={`${prefix}/reviews/star_half.svg`} alt="" />}

          {[...new Array(Math.floor(empty))].map((_, i) => (
            <img src={`${prefix}/reviews/star_empty.svg`} key={prefix} alt="" />
          ))}
        </div>
      </div>

      <div className={styles.item}>
        <span className={styles.item__title}>{t('reviews.quality')}:</span>
        <span className={styles.item__value}>
          {roundToHalf(quality).toFixed(1)}
        </span>
      </div>

      <div className={styles.item}>
        <span className={styles.item__title}>{t('reviews.schedule')}:</span>
        <span className={styles.item__value}>
          {roundToHalf(schedule).toFixed(1)}
        </span>
      </div>

      <div className={styles.item}>
        <span className={styles.item__title}>{t('reviews.cost')}:</span>
        <span className={styles.item__value}>
          {roundToHalf(cost).toFixed(1)}
        </span>
      </div>

      <div className={styles.item}>
        <span className={styles.item__title}>{t('reviews.refer')}:</span>
        <span className={styles.item__value}>
          {roundToHalf(refer).toFixed(1)}
        </span>
      </div>
    </div>
  );
};

export { ReviewCardRating };
