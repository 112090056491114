/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const FinanceStayWithYou: FC = () => {
  const prefix = 'cases.finance.stayWithYou';

  return (
    <div className={styles.wrap}>
      <div className={styles.colored}>
        <div className={styles.card}>
          <h2>
            <img src="/images/directions/finance/icons/contact.svg" alt="" />
            {t(`${prefix}.title`)}
          </h2>
          <p>{t(`${prefix}.desc`)}</p>
          <ul className={styles.primary_list}>
            {[...Array(5)].map((item, index) => (
              <li key={`list-${index + 1}`}>{t(`${prefix}.list.${index}`)}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FinanceStayWithYou;
