import React, { FC, useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { KmmSuccesModal } from '../KmmSuccesModal';
import { usePathPrefix } from '../../../../../constants/hooks';
import { sendFintechRequest } from '../../../../../utils/api';
import { OrderForm } from '../../../../common/OrderForm';
import { FinanceButton } from '../../components/FinanceButton';
import { OopsPanel } from '../../../../common/OopsPanel';

const FinanceForm: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_fintech';

  const prefix = usePathPrefix();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOops, setShowOops] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      if (!executeRecaptcha) return;
      const captcha = await executeRecaptcha(formName);
      const res = await sendFintechRequest({ ...data, captcha });

      if (res.data) {
        setIsModalOpen(true);
      }
    } catch (e) {
      const error = e as AxiosError;
      setErrorMessage(error.response?.data.error);
      setShowOops(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccessClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <>
      <OopsPanel isOpen={showOops} errorMessage={errorMessage} />

      <div className={styles.wrap}>
        <div className={styles.content} id="form">
          <h2>{t('cases.finance.form.title')}</h2>
          <div className={styles.form}>
            <OrderForm
              formId={formName}
              onSubmit={handleSubmit}
              isLoading={isLoading}
              isFormSucces={isModalOpen}
              withSubscribe
            />
          </div>
          <div className={styles.terms}>
            {t('cases.restocare.contact.terms')}{' '}
            <a
              href={`${prefix}/case/restocare/terms.pdf`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {t('cases.restocare.contact.personal_data')}
            </a>
          </div>
        </div>

        {isModalOpen && (
          <KmmSuccesModal onClose={onSuccessClose}>
            <div className={styles.result}>
              <h1>{t('cases.restocare.contact.success_title')}</h1>
              <div>{t('cases.restocare.contact.success_subtitle')}</div>
              <FinanceButton onClick={onSuccessClose}>OK</FinanceButton>
            </div>
          </KmmSuccesModal>
        )}
      </div>
    </>
  );
};

export { FinanceForm };
