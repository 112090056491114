/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const FinanceKmm: FC = () => {
  const prefix = 'cases.finance.kmm';

  const cards = [
    [
      '/images/directions/finance/icons/kmm_android.svg',
      '/images/directions/finance/icons/kmm_ios.svg',
    ],
    [
      '/images/directions/finance/icons/kmm_wallet.svg',
      '/images/directions/finance/icons/kmm_time.svg',
    ],
  ];

  return (
    <div className={styles.sectionWrap}>
      <div className={styles.wrap}>
        <h2>{t(`${prefix}.title`)}</h2>
        <p className={styles.desc}>{t(`${prefix}.desc`)}</p>
        <div className={styles.stepContainer}>
          {cards.map((item, index) => (
            <div key={`step-${index + 1}`} className={styles.step}>
              <p>{t(`${prefix}.cards.${index}`)}</p>
              <div className={styles.icons}>
                {cards[index].map((icon) => (
                  <img key={icon} src={icon} alt="" />
                ))}
              </div>
            </div>
          ))}
        </div>
        <p className={styles.develop_time}>{t(`${prefix}.developTime`)}</p>
      </div>
    </div>
  );
};

export default FinanceKmm;
