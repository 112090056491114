import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { FinanceStep } from '../../components/FinanceStep';

const FinanceDevelopBank: FC = () => {
  const prefix = 'cases.finance.developBank';

  return (
    <div className={styles.wrap}>
      <h2>{t(`${prefix}.title`)}</h2>
      <div className={styles.steps}>
        {[...Array(6)].map((item, index) => (
          <FinanceStep
            key={`step-${index + 1}`}
            stepNumber={index + 1}
            stepName={`${prefix}.steps.${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default FinanceDevelopBank;
