/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';
import { FinanceButton } from '../../components/FinanceButton';

interface IProps {
  title: string;
}

const FinanceHeader: FC<IProps> = ({ title }) => {
  const { formatMessage } = useIntl();

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={classNames(styles.wrap)}>
      <div className={classNames(root.breadcrumbs)}>
        <Breadcrumbs title={title} />
        <div className={styles.contacts}>
          <div>
            <a
              href={`tel:${formatMessage({
                id: 'cases.finance.header.phone',
              })}`}
            >
              {t('cases.finance.header.phone')}
            </a>
          </div>
          <div>
            <a
              href={`mailto:${formatMessage({
                id: 'cases.finance.header.email',
              })}`}
            >
              {t('cases.finance.header.email')}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.content}>
        <div className={styles.text}>
          <img
            src="/images/directions/finance/logo_Icerock.svg"
            alt="icerock"
          />
          <h1 className={styles.title}>{t('cases.finance.header.title')}</h1>
          <p className={styles.desc}>{t('cases.finance.header.desc')}</p>
          <FinanceButton onClick={scrollToForm}>
            {t('cases.finance.header.button')}
          </FinanceButton>
        </div>
        <div className={styles.image}>
          <div className={styles.radial_gradient} />
          <img src="/images/directions/finance/phones.png" alt="phones" />
        </div>
      </div>
    </div>
  );
};

export { FinanceHeader };
