/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  onClick?: () => void;
}

const KmmButton: FC<IProps> = ({ children, onClick = () => {} }) => (
  <button className={styles.button} onClick={onClick}>
    {children}
  </button>
);

export { KmmButton };
