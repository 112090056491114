/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { FinanceButton } from '../../components/FinanceButton';

const FinanceDevelopApp: FC = () => {
  const prefix = 'cases.finance.developApp';

  const icons = [
    {
      icon: '/images/directions/finance/icons/app_search.svg',
      list: [...Array(4)],
    },
    {
      icon: '/images/directions/finance/icons/app_develop.svg',
      list: [...Array(6)],
    },
    {
      icon: '/images/directions/finance/icons/app_testing.svg',
      list: [...Array(3)],
    },
  ];

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.wrap}>
      <h2>{t(`${prefix}.title`)}</h2>
      <p className={styles.desc}>{t(`${prefix}.desc`)}</p>
      <div className={styles.stepsContainer}>
        {icons.map((item, index) => (
          <div key={`step-${index + 1}`} className={styles.step}>
            <div className={styles.head}>
              <img src={item.icon} alt="" />
              <div className={styles.title}>
                {t(`${prefix}.steps.${index}.title`)}
              </div>
            </div>
            <ul className={styles.content}>
              {item.list.map((list, idx) => (
                <li key={`list-${idx + 1}`}>
                  {t(`${prefix}.steps.${index}.list.${idx}`)}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className={styles.btnContainer}>
        <FinanceButton bigSize onClick={scrollToForm}>
          {t(`cases.finance.header.button`)}
        </FinanceButton>
      </div>
    </div>
  );
};

export default FinanceDevelopApp;
