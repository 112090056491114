/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const FinancePromises: FC = () => {
  const prefix = 'cases.finance.promises';

  return (
    <div className={styles.wrap}>
      <div className={styles.colored_one}>
        <div className={styles.card}>
          <h2>
            <img src="/images/directions/finance/icons/settings.svg" alt="" />
            {t(`${prefix}.card_1.title`)}
          </h2>
          <ul className={styles.primary_list}>
            {[...Array(3)].map((item, index) => (
              <li key={`primary-list-${index + 1}`}>
                {t(`${prefix}.card_1.primaryList.${index}`)}
              </li>
            ))}
          </ul>
          <ul className={styles.list}>
            {[...Array(3)].map((item, index) => (
              <li key={`list-${index + 1}`}>
                {t(`${prefix}.card_1.list.${index}`)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.colored_two}>
        <div className={styles.card}>
          <h2>
            <img src="/images/directions/finance/icons/verified.svg" alt="" />
            {t(`${prefix}.card_2.title`)}
          </h2>
          <p>{t(`${prefix}.card_2.desc`)}</p>
          <ul className={styles.primary_list}>
            {[...Array(3)].map((item, index) => (
              <li key={`primary-list-${index + 1}`}>
                {t(`${prefix}.card_2.primaryList.${index}`)}
              </li>
            ))}
          </ul>
          <ul className={styles.list}>
            {[...Array(5)].map((item, index) => (
              <li key={`list-${index + 1}`}>
                {t(`${prefix}.card_2.list.${index}`)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FinancePromises;
